import React from 'react'
import { Box, Container, Flex, Heading, Text } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import RichTextRenderer from '../Content/RichText'
import CareerCard from './Card'

const CareerPage = ({ page }) => {
  const { hero, sections } = page
  const { title, heading, copy, description, media: careers } = sections[0]

  return (
    <Container variant="fullWidth" sx={{ width: ['100%', '80%'] }}>
      <Flex sx={{ justifyContent: 'center' }}>
        <Heading
          sx={{
            fontSize: [6, 8],
            textTransform: 'uppercase',
            display: 'inline-block',
            textAlign: 'center',
            pb: [3, 4],
            mb: [4, 6],
            position: 'relative',
            ':after': {
              content: '""',
              width: '30%',
              height: '1px',
              backgroundColor: 'border',
              display: 'block',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)'
            }
          }}
        >
          {title}
        </Heading>
      </Flex>
      <GatsbyImage
        image={hero.image.gatsbyImageData}
        alt={hero.image.description ?? hero.image.title}
      />
      <Box sx={{ fontSize: [2, 4], mt: 6, px: [4, 0] }}>
        <RichTextRenderer richText={heading} />
      </Box>
      <Box sx={{ fontSize: 3, px: [4, 0] }}>
        <RichTextRenderer richText={copy} />
      </Box>
      {/* <Flex sx={{ flexDirection: 'column', px: [3, 0] }}>
        {careers &&
          careers.length > 0 &&
          careers.map(career => (
            <CareerCard key={career.title} career={career} />
          ))}
        <Box
          sx={{
            mt: 6,
            '#hiringthing-jobs': {
              px: 3,
              '.ht-title-link': {
                fontSize: [6, 8],
                textAlign: 'center',
                textDecoration: 'underline',
                margin: '0 auto',
                mb: 4,
                fontWeight: 'bold'
              },
              '.ht-location': {
                fontWeight: 'medium',
                fontSize: 3,
                maxWidth: '800px !important',
                m: '0 auto'
              },
              '.ht-summary': {
                fontWeight: 'light',
                fontSize: 3,
                m: '30px auto 50px',
                maxWidth: '800px !important',
                strong: {
                  fontWeight: 'medium'
                }
              },
              '.ht-apply-link': {
                variant: 'buttons.primary',
                width: '143px',
                margin: '0 auto 100px',
                textAlign: 'center'
              }
            }
          }}
        >
          <div id="hiringthing-jobs"></div>
        </Box>
      </Flex> */}
      <Heading>
        JOB LISTINGS ARE TEMPORARILY DOWN FOR MAINTENANCE, PLEASE CHECK BACK
        SOON!
      </Heading>
      <Text
        sx={{
          a: { textDecoration: 'underline' },
          fontSize: 3,
          fontWeight: 'light',
          mt: 5,
          px: [3, 0]
        }}
      >
        If you believe that you can contribute to our team, we’d love to talk to
        you. Please email a copy of your resume to{' '}
        <a
          ariaLabel="careers email link"
          target="_blank"
          href="mailto:careers@super73.com"
          rel="noopener noreferrer"
        >
          careers@super73.com
        </a>
      </Text>
      <Text
        sx={{
          fontStyle: 'italic',
          fontSize: 3,
          fontWeight: 'light',
          mb: 7,
          mt: 3,
          px: [3, 0]
        }}
      >
        NOTE: This description is not intended to be an exhaustive list of all
        duties, responsibilities, or qualifications associated with this
        position. 90 day probation upon hire. Super73, Inc reserves the right to
        modify and change responsibilities and duties herein without notice.
      </Text>
    </Container>
  )
}

export default CareerPage
