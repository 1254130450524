import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import CareerPage from '~/components/Career/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const Careers = ({ data }) => {
  const { page } = data

  const setRipplingSettings = () => {
    var ht_settings = ht_settings || new Object()
    ht_settings.site_url = 'super73'
    ht_settings.open_jobs_in_new_tab = true
    window.ht_settings = ht_settings
  }

  useEffect(() => {
    setRipplingSettings()
    const script = document.createElement('script')
    script.src = 'https://assets.rippling-ats.com/javascripts/embed.js'
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    if (!page) {
      navigate('/')
    }
  }, [])

  return (
    <Layout>
      {page && (
        <>
          <Metadata
            title={page.metaTitle || page.title}
            description={
              page.metaDescription?.metaDescription ||
              `Super73's ${page.title} page default description`
            }
          />
          <link
            rel="stylesheet"
            type="text/css"
            media="all"
            href="https://assets.rippling-ats.com/stylesheets/embed.css"
          />
          <noscript>
            <a href="https://super73.rippling-ats.com">
              View our currently open positions.
            </a>
            <br />
            <br />
            <small>
              Job listings brought to you by{' '}
              <a href="https://www.rippling-ats.com">Rippling ATS</a>.
            </small>
          </noscript>
          <CareerPage page={page} />
        </>
      )}
    </Layout>
  )
}

export default Careers

export const query = graphql`
  query CareersPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "careers" }
      node_locale: { eq: $locale }
    ) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      hero {
        ...ContentHeroFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`
